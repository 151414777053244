let authUrl = 'http://localhost:8081';
if (window.location.hostname === "staging.kaizenovate.net") {
  authUrl = 'https://staging-auth.kaizenovate.net';
} else if (window.location.hostname === "www.kaizenovate.net") {
  authUrl = 'https://auth.kaizenovate.net';
}

let adminUrl = 'http://localhost:3000'
if (window.location.hostname === "staging.kaizenovate.net") {
  adminUrl = 'https://staging.kaizenovate.net';
} else if (window.location.hostname === "www.kaizenovate.net") {
  adminUrl = 'https://www.kaizenovate.net';
}

const config = {
    authUrl,
    adminUrl,
};


export default config;