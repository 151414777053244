import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input } from "@mui/material";
import { memo, useState } from "react"
import { ChannelSearchProps, SearchBar as DefaultSearchBar, SearchInput as DefaultSearchInput, SearchResults, useChatContext } from "stream-chat-react";
// import { useChannelSearch } from "stream-chat-react/dist/components/ChannelSearch/hooks/useChannelSearch";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import tokenManager from "../../tokenManager";
import { fetchUtils } from "react-admin";
import { useNavigate, useLocation } from 'react-router-dom';

const UnMemoizedCustomChannelSearch = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: ChannelSearchProps<StreamChatGenerics>,
) => {

  const {
    AppMenu,
    ClearInputIcon,
    ExitSearchIcon,
    MenuIcon,
    placeholder,
    popupResults = false,
    SearchBar = DefaultSearchBar,
    SearchEmpty,
    SearchInput = DefaultSearchInput,
    SearchLoading,
    SearchInputIcon,
    SearchResultItem,
    SearchResultsList,
    SearchResultsHeader,
    ...channelSearchParams
  } = props;
  // const { themeVersion } = useChatContext<StreamChatGenerics>('ChannelSearch');

  // const {
  //   activateSearch,
  //   clearState,
  //   exitSearch,
  //   inputIsFocused,
  //   inputRef,
  //   onSearch,
  //   query,
  //   results,
  //   searchBarRef,
  //   searching,
  //   selectResult,
  // } = useChannelSearch<StreamChatGenerics>(channelSearchParams);

  // const showSearchBarV2 = themeVersion === '2';

  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState("")
  const [phoneSubmitting, setPhoneSubmitting] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const phoneChangeHandler = (e) => {
    let formattedNumber;
  
    var { name, value } = e.target
    const { length } = value;
    
    //filter non numbers
    const regex = () => value.replace(/[^0-9\.]+/g, "")
  
    // set area code with parentheses
    const areaCode = () => {
      return `(${regex().slice(0, 3)})`
    }
  
    //set formatting for first six digits
    const firstSix = () => {
      return `${areaCode()} ${regex().slice(3, 6)}`
    }
  
    // dynamic trail as user types
    const trailer = (start) => {
      return `${regex().slice(start, regex().length)}`
    }
  
    if (length <= 3) {
      // First 3 digits
      formattedNumber = regex();
    } else if (length === 4) {
      // After area code
      formattedNumber = `${areaCode()} ${trailer(3)}`;
    } else if (length === 5) {
      // When deleting digits inside parenthesis
      formattedNumber = `${areaCode().replace(")", "")}`;
    } else if (length > 5 && length <= 9) {
      // Before dash
      formattedNumber = `${areaCode()} ${trailer(3)}`;
    } else if (length >= 10) {
      // After dash
      formattedNumber = `${firstSix()}-${trailer(6)}`;
    }

    if (length > 14) {
      return
    }

    setPhone(formattedNumber)
  
  }

  const submitPhone = () => {
    setPhoneError("")
    setPhoneSubmitting(true)
    const phoneString = phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")
    if (phoneString.length !== 10) {
      setPhoneError("Invalid phone number")
      setPhoneSubmitting(false)
      return;
    }

    const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
    const accessToken = tokenManager.getToken();

    const options = {}
    options['credentials'] = 'include'
    options['user'] = {
        authenticated: true,
        token: `Bearer ${accessToken}`,
    }

    const httpClient = fetchUtils.fetchJson;

    httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/create-chat-channel`, {
      method: 'POST',
      credentials: 'include',
      user: {
          authenticated: true,
          token: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        phone: phoneString,
        practice_id: localStorage.getItem('selectedPractice')
      }),
    }).then((result) => {
      if (result.json.message) {
        setPhoneError(result.json.message)
        setPhoneSubmitting(false)
      } else if (result.json.channel_id) {
        const searchParams = new URLSearchParams(location.search)
        searchParams.delete("channel_id")
        searchParams.set("channel_id", result.json.channel_id)
        navigate({
          pathname: location.pathname,
          search: searchParams.toString()
        }, {replace: true})
        setPhoneDialogOpen(false)
        setPhoneSubmitting(false)
        setPhone('')
      } else {
        setPhoneError("An error occurred")
        setPhoneSubmitting(false)
      }
    }).catch((e)=> {
        console.log(`Error in CustomChannelSearch:submitPhone()`)
        console.error(e)
        throw e
    })
  }

  const handleCloseDialog = () => {
    setPhoneDialogOpen(false);
    setPhone('')
    setPhoneError("")
  }

  const handleAddByPhoneClick = () => {
    setPhoneDialogOpen(true)
  }

  return (
    <div
      className={"str-chat__channel-search" + (popupResults ? ' str-chat__channel-search--popup' : ' str-chat__channel-search--inline') 
      // + (results.length > 0 ? ' str-chat__channel-search--with-results' : '')
      }
      data-testid='channel-search'
    >
      {localStorage.getItem('enhancedMessagingEnabled') === 'true' ? <AddChannelButton clickHandler={handleAddByPhoneClick}/> : null}
      <div>
          <Dialog
              open={phoneDialogOpen}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth='lg'
          >
              <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
                Add by Phone
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Enter a phone number to add a new channel
                  </DialogContentText>
                  <Input 
                      error={phoneError !== ""}
                      id="phone"
                      type="phone"
                      placeholder="555-555-5555"
                      value={phone}
                      onChange={phoneChangeHandler}
                      style={{
                        paddingTop: '25px',
                        width: '100%',
                        height: '100%'
                      }}
                  />
                  {phoneError !== "" ? <p style={{color: 'red'}}>{phoneError}</p> : null}
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleCloseDialog} autoFocus>
                      Cancel
                  </Button>
                  <Button onClick={submitPhone} autoFocus disabled={phoneSubmitting}>
                      Submit
                  </Button>
              </DialogActions>
          </Dialog>
      </div>
      {/* {showSearchBarV2 ? (
        <SearchBar
          activateSearch={activateSearch}
          AppMenu={AppMenu}
          ClearInputIcon={ClearInputIcon}
          clearState={clearState}
          disabled={channelSearchParams.disabled}
          exitSearch={exitSearch}
          ExitSearchIcon={ExitSearchIcon}
          inputIsFocused={inputIsFocused}
          inputRef={inputRef}
          MenuIcon={MenuIcon}
          onSearch={onSearch}
          placeholder={placeholder}
          query={query}
          searchBarRef={searchBarRef}
          SearchInput={SearchInput}
          SearchInputIcon={SearchInputIcon}
        />
      ) : (
        <SearchInput
          clearState={clearState}
          disabled={channelSearchParams.disabled}
          inputRef={inputRef}
          onSearch={onSearch}
          placeholder={placeholder}
          query={query}
        />
      )}
      {query && (
        <SearchResults
          popupResults={popupResults}
          results={results}
          SearchEmpty={SearchEmpty}
          searching={searching}
          SearchLoading={SearchLoading}
          SearchResultItem={SearchResultItem}
          SearchResultsHeader={SearchResultsHeader}
          SearchResultsList={SearchResultsList}
          selectResult={selectResult}
        />
      )} */}
    </div>
  )
}

const AddChannelButton = ({ clickHandler }) => {
  return (
    <div className='add-channel' style={{width: '100%'}}>
      <Button onClick={() => clickHandler()}>
        Add by Phone
      </Button>
    </div>
  )
}

export const CustomChannelSearch = memo(UnMemoizedCustomChannelSearch) as typeof UnMemoizedCustomChannelSearch;