import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useNavigate } from 'react-router-dom';
import { 
    TextField,
    Datagrid,
    BulkDeleteButton,
    BulkUpdateButton,
    useRecordContext,
    FieldProps,
    ReferenceField,
    ReferenceManyField,
    TopToolbar,
    Show,
    Button,
    useUpdate,
    useGetIdentity,
    useRedirect
} from 'react-admin';
import { useEffect } from 'react';
import { Patient } from '../../types';
import { format } from 'date-fns';

import ExerciseRefField from '../exercises/ExerciseRefField';
import dataProvider from '../../dataProvider';
import tokenManager from '../../tokenManager';
import { logActivity } from '../../logActivity.js'
import SessionTimeout from '../../SessionTimeout';

const HomeExercisePlan = (props: FieldProps<Patient>) => {

    var date = Number(localStorage.getItem('testDate'))
    if (!date) {
        date = 0
    }
    
    const record = useRecordContext<Patient>();

    const [update, { isLoading }] = useUpdate();
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const redirect = useRedirect()

    var startTime = new Date();

    // add new activity log
    const updateWithNewRecord = async (newActivity) => {

        if (newActivity.duration <= 1) return

        var currRecord = record;
        if(identityLoading && !isLoading && tokenManager.getToken()) {
            await dataProvider.getOne(`users`, record).then(result => {
                currRecord = result.data;
            });
        }

        var currDate = currRecord.start_billing_date;
        var currTime = currRecord.start_billing_time;
        var currBillingDuration = currRecord.current_billing_duration;

        if( currDate == null || 
            currDate === "" ||
            currTime == null || 
            currTime === "" ) {
            currDate = format(new Date(), 'yyyy-MM-dd');
            currTime = format(new Date(), 'HH:mm');
        }
        
        if( currBillingDuration == null || 
            currBillingDuration === 0) {
            currBillingDuration = newActivity.duration
        } else {
            currBillingDuration = currRecord.current_billing_duration + newActivity.duration
        }

        // add to the beginning of array - the order of these activities are in the same order as displayed on activity log table ... bottom to top
        const diff = { 
            current_billing_duration: currBillingDuration,
            start_billing_date: currDate,
            start_billing_time: currTime,
        };

        update(
            'users',
            { id: currRecord.id, data: diff, previousData: currRecord }
        )

        logActivity('PROVIDER.View.HEP', newActivity.activity, newActivity.comment, currRecord.id, newActivity.duration, date)
    }

    // Add activity log when moving away from Home Exericse Plan
    useEffect(
        () => {
            return () => {
                const duration = Math.round((new Date().getTime() - startTime.getTime())/1000);
                var newActivity = {
                    "date": Math.round(Date.now()/1000),
                    "duration": duration,
                    "activity": "Viewed Home Exercise Plan",
                    "first": identity?.firstName ?? "",
                    "last": identity?.lastName ?? "",
                    "email": identity?.username ?? "", // TODO is email going to be used as username?
                    "picture": identity?.picture ?? "",
                    "comment": "None",
                    "billable": false // TODO check to see if the current viewing period is billable
                };
                updateWithNewRecord(newActivity);
            }
        },
        [!identityLoading]
    );

    return (
        <div>
            <SessionTimeout isAuthenticated={true} logOut={() => {redirect('/users')}}/>
            <Show actions={false} >
                <ListActions />
                <ReferenceManyField 
                reference="exercises" 
                target="patient_id" 
                // sort={{ field: 'sequence', order: 'ASC' }}
                sort={{ field: 'condition_id,display_level,sequence', order: "ASC" }}
                >
                    <Datagrid
                        bulkActionButtons={<PostBulkActionButtons />}
                        // bulkActionButtons={false}
                    >
                        <ExerciseRefField source="id" />
                        <TextField source="display_level" label="Level" />
                        <ReferenceField
                            source="condition_id"
                            reference="conditions"
                            link={false}
                        >
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="sequence" />
                        <ReferenceField
                            source="easier_exercise.exercise"
                            reference="exerciselibrarys"
                            link={false}
                            label="Easier Exercise"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField
                            source="default_exercise.exercise"
                            reference="exerciselibrarys"
                            link={false}
                            label="Default Exercise"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField
                            source="harder_exercise.exercise"
                            reference="exerciselibrarys"
                            link={false}
                            label="Harder Exercise"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="status" /> 
                    </Datagrid>
                </ReferenceManyField>
            </Show>
        </div>
    )
}

const PostBulkActionButtons = () => (
    <>
        <BulkUpdateButton 
        label="Active" 
        icon={<ThumbUpIcon />}
        data={{ status: "active" }}
        mutationMode="pessimistic"
        />
        <BulkUpdateButton 
        label="Inactive" 
        icon={<ThumbDownIcon />}
        data={{ status: "inactive" }}
        mutationMode="pessimistic"
        />
        <BulkDeleteButton 
        mutationMode="pessimistic"
        />
    </>
);

const CreateExerciseLink = () => {

    const record = useRecordContext();
    const navigate = useNavigate();

    return <Button label="Create" onClick={
        () => {
            navigate('/exercises/create', {state:{id: record.id }})
        }
    }></Button>

}

const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        <CreateExerciseLink />
    </TopToolbar>
);

export default HomeExercisePlan;
