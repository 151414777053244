import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import transform from 'lodash/transform';
import { getCondition, getExerciseLibrary } from './getData.js'
import { logActivity } from './logActivity.js'
import tokenManager from './tokenManager';

const date = localStorage.getItem('testDate')

// if we're using localhost then let's assume we are running react admin separately
const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
const httpClient = (url, options = {}) => {
    const accessToken = tokenManager.getToken();
    options.credentials = 'include'
    options.user = {
        authenticated: true,
        token: `Bearer ${accessToken}`,
    }
    /*
    options.headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        selectedPractice
    })
    */
    // options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const httpClientWithPractice = (url, options = {}) => {
    const accessToken = tokenManager.getToken();
    const selectedPractice = localStorage.getItem('selectedPractice') || ''
    options.credentials = 'include'
    options.user = {
        authenticated: true,
        token: `Bearer ${accessToken}`,
    }
    if (!url.includes('provider/1.0.0/practices') && (url.includes('/users') || url.includes('/billings') || url.includes('/messagebroadcasts') || url.includes('/messagetemplates') || url.includes('/messageautomations') || url.includes('/providernotifications') || url.includes('/messagelogs') || url.includes('/providers') || url.includes('/appointmenttypes'))) {
        if (url.includes('?')) {
            url += `&practice=${selectedPractice}`
        } else {
            url += `?practice=${selectedPractice}`
        }
    }
    /*
    options.headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        selectedPractice
    })
    */
    // options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};


const convertToWaterline = (filters) =>
    transform(filters, (result, value, key) => {

        if (typeof value === "string") {
//            const keyWithoutSuffix = key.slice(0, -1)
            const words = value.trim().split(" ")
            if (words.length === 1) {
                result[key] = { contains: words[0] }
            } else {
                result["and"] = words.map(word => ({ [key]: { contains: word } }))
            }
        } else {
            result[key] = value
        }

        return result
    }, {})

// Good reference for sails dataprovider: https://github.com/mpampin/ra-data-json-sails/blob/master/src/index.js

export default {
    getList: async (resource, params) => {
        console.log(`getList for ${resource}`)
        console.log(params)

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { groupBy, count, max, ...filters } = params.filter;
        const where = convertToWaterline(filters)
        console.log(`Where after convertToWaterline`)
        console.log(where)

        // if searching patients by name, convert to upper case 
        if (resource === "users" && where.first) {
            where.first.contains = where.first.contains.toUpperCase()
        }
        if (resource === "users" && where.last) {
            where.last.contains = where.last.contains.toUpperCase()
        }
        if (resource === "users") {
            if (where.status) {
                if (where.and) {
                    var andArr = where.and
                    andArr.push({"status": {'!=': 'discharged'}})
                    andArr.push({"status": where.status})
                    where.and = andArr
                    delete where.status
                } else {
                    where.and = [{"status": {'!=': 'discharged'}}, {"status": where.status}]
                    delete where.status
                }
            } else {
                where.status = {'!=': 'discharged'}
            }
        }

        // message automations list page
        if (resource === 'messageautomations') {
          where.is_provider_notification = false
          if (!where.type) {
            where.type = { '!=': 'now' }
          }
        }

        // broadcasts list page
        if (resource === 'messagebroadcasts') {
          resource = 'messageautomations'
          where.is_provider_notification = false
          where.is_broadcast = true
        }

        // provider notifications list page
        if (resource === 'providernotifications') {
          resource = 'messageautomations'
          // modify where to only include provider notifications
          where.is_provider_notification = true
        }

        //https://sailsjs.com/documentation/reference/blueprint-api/find-where
        /// exerciselibrarys?limit=999&skip=0&sort=id ASC&where={"name":"Low"}

        // workaround filtering live search - adding waterline word "contains"
        if(
            (
                resource === "exerciselibrarys" ||
                resource === "masterprograms"
            )   && where.name 
        ) {
            where.name = { contains: where.name }
        } 
        if(resource === "users" && where.name ) {
            if (where.name.contains) where.name = where.name.contains
            let nameArr = where.name.toUpperCase().split(' ');
            let orArr = [];
            nameArr.map((name) => {
                orArr.push({ first: { contains: name } })
                orArr.push({ last: { contains: name } })
            })
            where.or = orArr;
            delete where.name;
        } 
        if (resource === 'logentries') {
            if (where.user && where.user.contains) {
                where.user = where.user.contains
            }
        }

        if (resource === "billings") {
            if (where.cpt_code) {
                if (where.and) {
                    andArr = where.and
                    andArr.push({"cpt_code": where.cpt_code})
                    andArr.push({"cpt_code": {'!=': 'fakeForSplitLogs'}})
                    where.and = andArr
                    delete where['cpt_code']
                } else {
                    where.and = [{"cpt_code": where.cpt_code}, {"cpt_code": {'!=': 'fakeForSplitLogs'}}]
                    delete where['cpt_code']
                }
            } else {
                where.cpt_code = {'!=': 'fakeForSplitLogs'}
            }

            if (where.lastName) {
                where.lastName.contains = where.lastName.contains.toUpperCase()
            }
        }

        const query = {
            where: JSON.stringify(where), 
            skip: (page - 1) * perPage,
            // limit: perPage, // default 30 in sails
            limit: perPage,
            sort: resource === 'logentries' ? 'date DESC' : `${field} ${order}`,
            // select,
            // omit,
        };

        var url = `${apiUrl}/${resource}?${stringify(query)}`;

        if (resource === "logs") {
            var startDate = new Date()
            var endDate = new Date()
            startDate.setHours(0, 0, 0, 0)
            

            if (where.time_gte) {
                const startArr = where.time_gte.contains.split("-")
                startDate.setFullYear(startArr[0], startArr[1] - 1, startArr[2])
            } else {
                startDate = new Date(startDate.getTime() - (1000 * 60 * 60 * 24 * 7))
            }

            if (where.time_lte) {
                const endArr = where.time_lte.contains.split("-")
                endDate.setHours(0, 0, 0, 0)
                endDate.setFullYear(endArr[0], endArr[1] - 1, endArr[2])
            }

            endDate.setHours(23, 59, 59, 999)
            const end = endDate.toISOString()
            const start = startDate.toISOString()
            url = `${apiUrl}/kaizenovate/compliance/1.0.0/export-log-drain?start=${start}&end=${end}&page=${page}&perPage=${perPage}`
        }


        try {
            return httpClientWithPractice(url).catch((e)=> {
                console.log(`Error during getList`)
                console.error(e);
                throw e
            }).then(({ headers, json }) => {
                if (json.results) return {
                    data: json.results,
                    total: json.totalCount,
                }
                else return {
                    data: json,
                    total: json.length,
                }
            });
        } catch (e) {
            console.log(`Error during getList outer`)
            console.error(e);
            throw e
        }
    },

    getOne: (resource, params) => {
        console.log(`getOne for ${resource}`)
        console.log(params)

        //catch resource = 'patientBillings' and send to sorrect endpoint
        if (resource === 'patientBillings') {
            return httpClient(`${apiUrl}/kaizenovate/billings/1.0.0/get-patient-billing-details?patient=${params.id}`).catch((e)=> {
                console.error(`Error trying to getOne`)
                console.error(e)
                throw e
            }).then(({ json }) => ({
                data: json,
            }))
          } else if (resource === 'users') {
              return httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/get-patient-details?user_id=${params.id}`).catch((e)=> {
                  console.error(`Error trying to getOne`)
                  console.error(e)
                  throw e
              }).then(({ json }) => ({
                  data: json,
              }))
          } else if (resource === 'providernotifications') {
            resource = 'messageautomations'
          }

        return httpClient(`${apiUrl}/${resource}/${params.id}`).catch((e)=> {
            console.error(`Error trying to getOne`)
            console.error(e)
            throw e
        }).then(({ json }) => ({
            data: json,
        }))
    },

    get: (resource) => {
        console.log(`get for ${resource}`)
        return httpClient(`${apiUrl}/${resource}`).catch((e)=> {
            console.error(`Error trying to get`)
            console.error(e)
            throw e
        }).then(({ json }) => ({
            data: json,
        }))
    },

    getMany: (resource, params) => {

        console.log(`getMany called for ${resource}`)
        console.log(params)

        if (resource === 'users' && params.ids && Array.isArray(params.ids)) {
          return httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/get-patient-details?user_id[]=${params.ids.join('&user_id[]=')}`).catch((e)=> {
              console.error(`Error trying to getMany`)
              console.error(e)
              throw e
          }).then(({ json }) => ({
              data: json,
          }))
        }

        let where
        if (params.ids) {
            where = {id: {in: params.ids}}
        }

        if (where) {
            where = convertToWaterline(where)
            console.log('Where after convertToWaterline')
            console.log(where)
        }
        // TODO
        // Need to do query similar to "getList"
        // const url = `${apiUrl}/${resource}?${stringify(query)}`;
        var url = `${apiUrl}/${resource}`;
        if (where) {
            url += `?where=${JSON.stringify(where)}`
        }
        return httpClient(url).catch((e)=> {
            console.error(`Error trying to getMany`)
            console.error(e)
            throw e
        }).then(({ json }) => ({ 
            data: json.results,
            total: json.totalCount,
        }));
    },

    getManyReference: (resource, params) => {

        console.log(`getManyReference called for ${resource}`)
        console.log(params)

        var { field, order } = params.sort || {};
        // const { page, perPage } = params.pagination;
        // const { field, order } = params.sort;

        const { groupBy, count, max, ...filters} = params.filter;
        const where = convertToWaterline(filters)
        where[params.target] = params.id
        
        //https://sailsjs.com/documentation/reference/blueprint-api/find-where

        // const query = {};

        if (field === "condition_id,display_level,sequence") {
            order = "DESC,ASC,ASC"
        }
        
        const query = {
            where: JSON.stringify(where), 
            skip: 0,
            // limit: 9999, // need to get all so the lookups work properly
            sort: `${field} ${order}`,
            // select,
            // omit,
        };

        if (field) {
            const fieldParts = field.split(',');
            const orderParts = order.split(',');
            var querySortString = fieldParts
                .map(
                    (field, index) =>
                        `{"${field}": "${orderParts[index]}"}`
                )
                .join(',');
            querySortString = JSON.parse("[" + querySortString + "]");
            query.sort = JSON.stringify(querySortString);
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        try {
            return httpClient(url).catch((e)=> {
                console.error(`Error trying to getManyReference`)
                console.error(e)
                throw e
            }).then(({ headers, json }) => ({
                data: json.results,
                // total: parseInt(headers.get('content-range').split('/').pop(), 10),
                total: json.totalCount,
            }));
        } catch (e) {
            console.log(`Error trying to getManyReference outer`);
            console.error(e)
            throw e
        }
    },

    update: async (resource, params) => {

        console.log(`updated called for ${resource}`)
        console.log(params)


        if (resource === 'exercises') {
            let condition;
            let easierEx = ``;
            let defaultEx = ``;
            let harderEx = ``;
            //get condition
            condition = await getCondition(params.data.condition_id)
            //get exercises
            //default exercise
            defaultEx = await getExerciseLibrary(params.data.default_exercise.exercise)
            //easier exercise if there is one
            if (params.data.easier_exercise.exercise && params.data.easier_exercise.exercise !== "") {
                easierEx = await getExerciseLibrary(params.data.easier_exercise.exercise)
            }
            //harder exercise if there is one
            if (params.data.harder_exercise.exercise && params.data.harder_exercise.exercise !== "") {
                harderEx = await getExerciseLibrary(params.data.harder_exercise.exercise)
            }

            let exNames = [easierEx, defaultEx, harderEx].filter(n =>n)
            let logString = ``;
            let logName = '';
            if (params.data.status !== params.previousData.status) {
                if (params.data.status === 'active') {
                    logString = `Provider activated the following exercise in the ${condition} HEP for level ${params.data.level} (${exNames.join('/')})`;
                    logName = 'PROVIDER.Exercise.Activate'
                } else {
                    logString = `Provider deactivated the following exercise in the ${condition} HEP for level ${params.data.level} (${exNames.join('/')})`;
                    logName = 'PROVIDER.Exercise.Deactivate'
                }
            } else if (params.data !== params.previousData) {
                let previousDefaultEx = '';
                let previousEasierEx = '';
                let previousHarderEx = '';
                //get old exercise names
                previousDefaultEx = await getExerciseLibrary(params.previousData.default_exercise.exercise)
                //easier exercise if there is one
                if (params.data.easier_exercise.exercise && params.previousData.easier_exercise.exercise !== "") {
                    previousEasierEx = await getExerciseLibrary(params.previousData.easier_exercise.exercise)
                }
                //harder exercise if there is one
                if (params.data.harder_exercise.exercise && params.previousData.harder_exercise.exercise !== "") {
                    previousHarderEx = await getExerciseLibrary(params.previousData.harder_exercise.exercise)
                }

                let previousExNames = [previousEasierEx, previousDefaultEx, previousHarderEx].filter(n =>n)
                let previousExSets = [params.previousData.easier_exercise.sets, params.previousData.default_exercise.sets, params.previousData.harder_exercise.sets].filter(n =>n)
                let previousExReps = [params.previousData.easier_exercise.reps, params.previousData.default_exercise.reps, params.previousData.harder_exercise.reps].filter(n =>n)
                let previousExRest = [params.previousData.easier_exercise.rest, params.previousData.default_exercise.rest, params.previousData.harder_exercise.rest].filter(n =>n)
                let previousExHold = [params.previousData.easier_exercise.hold, params.previousData.default_exercise.hold, params.previousData.harder_exercise.hold].filter(n =>n)

                let exSets = [params.data.easier_exercise.sets, params.data.default_exercise.sets, params.data.harder_exercise.sets].filter(n =>n)
                let exReps = [params.data.easier_exercise.reps, params.data.default_exercise.reps, params.data.harder_exercise.reps].filter(n =>n)
                let exRest = [params.data.easier_exercise.rest, params.data.default_exercise.rest, params.data.harder_exercise.rest].filter(n =>n)
                let exHold = [params.data.easier_exercise.hold, params.data.default_exercise.hold, params.data.harder_exercise.hold].filter(n =>n)

                logString = `Provider modified the following exercise in the ${condition} HEP for level ${params.data.level}
                    OLD: (${previousExNames.join('/')}, sets: ${previousExSets.join('/')}, reps: ${previousExReps.join('/')}, rest: ${previousExRest.join('/')}, hold: ${previousExHold.join('/')}),
                    NEW: (${exNames.join('/')}, sets: ${exSets.join('/')}, reps: ${exReps.join('/')}, rest: ${exRest.join('/')}, hold: ${exHold.join('/')})`
                logName = 'PROVIDER.Exercise.Modify'
            }
            if (logString !== ``) {
                await logActivity(logName, 'Edited Patient Home Exercise Plan', logString, params.data.patient_id, 0, date)
            }
        } else if (resource === 'logentries') {
            let logResult
            try {
                logResult = await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/update-activity`, {
                    method: 'POST',
                    body:JSON.stringify({
                        logId: params.id,
                        comment: params.data.comment
                    })
                })
            } catch (e_log) {
                console.log('Error trying to update log entry')
                console.log(e_log)
                throw e_log
            }
            const { json: json_log } = logResult
            return ({ data: json_log })
        } else if (resource === 'messageautomations') {
            let automationResult
            try {
                automationResult = await httpClient(`${apiUrl}/kaizenovate/communication/1.0.0/message-automation/edit`, {
                    method: 'POST',
                    body:JSON.stringify({...params.data})
                })
            } catch (e_broadcast) {
                console.log('Error trying to update automation entry')
                console.log(e_broadcast)
                throw e_broadcast
            }
            const { json: json_automation } = automationResult
            return ({
                data: { id: json_automation.id },
            });
        } else if (resource === 'providernotifications') {
            let notificationResult
            try {
                notificationResult = await httpClient(`${apiUrl}/kaizenovate/communication/1.0.0/provider-notification/edit`, {
                    method: 'POST',
                    body:JSON.stringify({...params.data})
                })
            } catch (e_notification) {
                console.log('Error trying to update notification entry')
                console.log(e_notification)
                throw e_notification
            }
            const { json: json_notification } = notificationResult
            return ({
                data: { id: json_notification.id },
            });
        } else if (resource === 'conditions') {
            let conditionsResult
            let conditionPayload = {
              id: params.data.id,
            }
            if (params.data.diagnosis_codes && params.data.diagnosis_codes !== "") {
              conditionPayload.diagnosis_codes = params.data.diagnosis_codes
            }
            if (params.data.image && params.data.image !== "") {
              conditionPayload.image = params.data.image
            }
            if (params.data.masterprogram_id && params.data.masterprogram_id !== "") {
              conditionPayload.masterprogram_id = params.data.masterprogram_id
            }
            try {
                conditionsResult = await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/update-condition`, {
                    method: 'POST',
                    body: JSON.stringify(conditionPayload)
                })
            } catch (e) {
                console.log("Error trying to update condition")
                console.log(e)
                throw e
            }
            const { json: json_conditions } = conditionsResult
            return ({
                data: { id: json_conditions.id },
            });
        }

        
        let result;
        try {
            result = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            });
        } catch (e_4) {
            console.log(`Error trying to update`)
            console.error(e_4);
            throw e_4
        }
        const { json: json_4 } = result;
        return ({ data: json_4 });
    },

    updateMany: (resource, params) => {

        console.log(`updatedMany called for ${resource}`)
        console.log(params)

        // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead

        return Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                  method: 'PATCH',
                  body: JSON.stringify(params.data),
                }).catch((e)=> {
                  console.log(`Error in updateMany`)
                  console.error(e);
                  throw e
                })
            )
        ).then(responses => ({
            data: responses.map(response => response.json),
        }));

    },

    create: async (resource, params) => {

        console.log(`create called for ${resource}`)
        console.log(params)


        if (resource === 'exercises') {
            let condition;
            let easierEx = ``;
            let defaultEx = ``;
            let harderEx = ``;

            //get condition
            condition = await getCondition(params.data.condition_id)
            //get exercises
            //default exercise
            defaultEx = await getExerciseLibrary(params.data.default_exercise.exercise)
            //easier exercise if there is one
            if (params.data.easier_exercise.exercise && params.data.easier_exercise.exercise !== "") {
                easierEx = await getExerciseLibrary(params.data.easier_exercise.exercise)
            }
            //harder exercise if there is one
            if (params.data.harder_exercise.exercise && params.data.harder_exercise.exercise !== "") {
                harderEx = await getExerciseLibrary(params.data.harder_exercise.exercise)
            }

            let exNames = [easierEx, defaultEx, harderEx].filter(n =>n)
            let exSets = [params.data.easier_exercise.sets, params.data.default_exercise.sets, params.data.harder_exercise.sets].filter(n =>n)
            let exReps = [params.data.easier_exercise.reps, params.data.default_exercise.reps, params.data.harder_exercise.reps].filter(n =>n)
            let exRest = [params.data.easier_exercise.rest, params.data.default_exercise.rest, params.data.harder_exercise.rest].filter(n =>n)
            let exHold = [params.data.easier_exercise.hold, params.data.default_exercise.hold, params.data.harder_exercise.hold].filter(n =>n)

            let logString = `Provider added the following exercise to the ${condition} HEP for level ${params.data.level}
                (${exNames.join('/')}, sets: ${exSets.join('/')}, reps: ${exReps.join('/')}, rest: ${exRest.join('/')}, hold: ${exHold.join('/')})`
            let logName = 'PROVIDER.Exercise.Add'

            await logActivity(logName, 'Edited Patient Home Exercise Plan', logString, params.data.patient_id, 0, date)
        }

        if (resource === 'messagebroadcasts') {
            let broadcastResult
            try {
                broadcastResult = await httpClient(`${apiUrl}/kaizenovate/communication/1.0.0/create-broadcast-message`, {
                    method: 'POST',
                    body:JSON.stringify({...params.data})
                })
            } catch (e_broadcast) {
                console.log('Error trying to create broadcast entry')
                console.log(e_broadcast)
                throw e_broadcast
            }
            const { json: json_broadcast } = broadcastResult
            return ({
                data: { id: json_broadcast.id },
            });
        }

        if (resource === 'providernotifications') {
          let notificationResult
          try {
              notificationResult = await httpClient(`${apiUrl}/kaizenovate/communication/1.0.0/provider-notification/create`, {
                  method: 'POST',
                  body:JSON.stringify({...params.data})
              })
          } catch (e_notification) {
              console.log('Error trying to create notification entry')
              console.log(e_notification)
              throw e_notification
          }
          const { json: json_notification } = notificationResult
          return ({
              data: { id: json_notification.id },
          });
        }

        if (resource === 'messageautomations') {
          let automationResult
          try {
            automationResult = await httpClient(`${apiUrl}/kaizenovate/communication/1.0.0/message-automation/create`, {
                  method: 'POST',
                  body:JSON.stringify({...params.data})
              })
          } catch (e_broadcast) {
              console.log('Error trying to create automation entry')
              console.log(e_broadcast)
              throw e_broadcast
          }
          const { json: json_automation } = automationResult
          return ({
              data: { id: json_automation.id },
          });
      }

        let result_1;
        try {
            result_1 = await httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            });
        } catch (e_4) {
            console.log(`Error in create`)
            console.error(e_4);
            throw (e_4)
        }
        const { json: json_4 } = result_1;
        return ({
            data: { ...params.data, id: json_4.id, extra: json_4 },
        });
    },

    delete: async (resource, params) => {

        console.log(`delete called for ${resource}`)
        console.log(params)


        if (resource === 'exercises') {
            let condition;
            let easierEx = ``;
            let defaultEx = ``;
            let harderEx = ``;

            //get condition
            condition = await getCondition(params.previousData.condition_id)
            //get exercises
            //default exercise
            defaultEx = await getExerciseLibrary(params.previousData.default_exercise.exercise)
            //easier exercise if there is one
            if (params.previousData.easier_exercise.exercise && params.previousData.easier_exercise.exercise !== "") {
                easierEx = await getExerciseLibrary(params.previousData.easier_exercise.exercise)
            }
            //harder exercise if there is one
            if (params.previousData.harder_exercise.exercise && params.previousData.harder_exercise.exercise !== "") {
                harderEx = await getExerciseLibrary(params.previousData.harder_exercise.exercise)
            }

            let exNames = [easierEx, defaultEx, harderEx].filter(n =>n)
            let exSets = [params.previousData.easier_exercise.sets, params.previousData.default_exercise.sets, params.previousData.harder_exercise.sets].filter(n =>n)
            let exReps = [params.previousData.easier_exercise.reps, params.previousData.default_exercise.reps, params.previousData.harder_exercise.reps].filter(n =>n)
            let exRest = [params.previousData.easier_exercise.rest, params.previousData.default_exercise.rest, params.previousData.harder_exercise.rest].filter(n =>n)
            let exHold = [params.previousData.easier_exercise.hold, params.previousData.default_exercise.hold, params.previousData.harder_exercise.hold].filter(n =>n)

            let logString = `Provider removed the following exercise from the ${condition} HEP for level ${params.previousData.level}
                (${exNames.join('/')}, sets: ${exSets.join('/')}, reps: ${exReps.join('/')}, rest: ${exRest.join('/')}, hold: ${exHold.join('/')})`
            let logName = 'PROVIDER.Exercise.Remove'

            await logActivity(logName, 'Edited Patient Home Exercise Plan', logString, params.previousData.patient_id, 0, date)
        }
        
        let result;
        try {
            result = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'DELETE',
            });
        } catch (e) {
            console.log(`Unable to delete, error follows`)
            console.error(e)
            throw e
        }
        const { json } = result;
        return ({ data: json });
    },

    deleteMany: (resource, params) => {
        console.log(`deleteMany called for ${resource}`)
        console.log(params)

        // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead

        return Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                }).catch((e)=> {
                    console.log(`Error during deleteMany`)
                    console.error(e)
                    throw e
                })
            )
        ).then(responses => ({
            data: responses.map(response => response.json),
        }));

    }
};